const TZ = "America/Sao_Paulo"

export function formattedDate(date: string): string {
  const dayjs = useDayjs()

  return dayjs(date).tz(TZ).format("DD/MM/YYYY")
}

export function localizedDate(date: string): string {
  const dayjs = useDayjs()

  return dayjs(date).tz(TZ).locale('pt-br').format("DD [de] MMMM [de] YYYY")
}
